export const sliceTo10Digits = (item) => {
  if (item?.length > 10) {
    const first5 = item.slice(0, 5);
    const last5 = item.slice(item.length - 5, item.length);

    return `${first5}...${last5}`;
  } else {
    return item;
  }
};

// export const NO_LOCAL_STORAGE = typeof window === 'undefined' || !localStorage,
//   LOCAL_STORAGE = !NO_LOCAL_STORAGE;

export const splitDigitsBy3 = (input) => {
  input = input && input.replace(/\s/g, "");
  return input && input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommas = (x) => {
  if (x < 1 || !x) return x;
  return parseInt(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const deleteGarbagefloats = (i, ac) => {
  if (!i || !ac) return i;

  return i.toString().slice(0, ac + 1);
};

export function floatNumberWithCommas(val, acFl = 2) {
  if (!val) return val;
  const x = val?.toString();
  const floatPoint = x.indexOf(".");
  if (floatPoint < 0) return numberWithCommas(x);

  const naturalNo = numberWithCommas(x.slice(0, floatPoint)),
    floatNo = x.slice(floatPoint, x.length),
    res = naturalNo + deleteGarbagefloats(floatNo, acFl);

  return res;
}
