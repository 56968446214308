import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default function Tables() {
  const [data, setData] = useState([]);
  const [hasToken, setHasToken] = useState(false);
  const [sec, setSec] = useState("");
  const [error, setError] = useState("");

  const getData = async () => {
    try {
      const newData = await axios.get(
        `https://ttsx-server.tetherland.app//transactions?sec=${sec}&wallet=4`,
      );
      if (newData.data.status === 1) {
        setHasToken(true);
        setData(newData.data.data);
      } else {
        setHasToken(false);
        setError(newData.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const classes = useStyles();
  return (
    <>
      <PageTitle title="Transactions" />
      <Grid container spacing={4}>
        <button onClick={() => getData()}>Refresh</button>
        <Grid item xs={12}>
          {hasToken && data.length > 0 ? (
            <Widget
              // title="Material-UI Table"
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
            >
              <Table sec={sec} getData={getData} data={data} />
            </Widget>
          ) : (
            <>
              <input value={sec} onChange={(e) => setSec(e.target.value)} />
              <button onClick={getData}>Send</button>
              {error && <div>{error}</div>}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
